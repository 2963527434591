import React, { useState, useRef } from 'react';
import '@google/model-viewer';
import { Link } from 'react-router-dom';

function ModelViewer({ modelName }) {
  const modelViewerRef = useRef();
  const [background, setBackground] = useState('white');
  const [loading, setLoading] = useState(true);

  const modelPath = `/assets/${modelName}.glb`;  // Assuming models are stored in /assets/

  const toggleFullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen();
    } else {
      modelViewerRef.current.requestFullscreen();
    }
  };

  const downloadModel = () => {
    const link = document.createElement('a');
    link.href = modelPath;
    link.download = `${modelName}.glb`;
    link.click();
  };

  return (
    <div className="relative w-full h-screen bg-gray-100 pt-24">
      <div> 
        <model-viewer
          alt={modelName}
          src={modelPath}
          shadow-intensity="1"
          camera-controls
          touch-action="pan-y"
          style={{ width: '100%', height: '800px' }}
          className="rounded-lg"
        >
        </model-viewer> 
        </div>
    </div>
  );
}

export default ModelViewer;
