// src/Navbar.js
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { HomeIcon, CubeIcon, InformationCircleIcon } from '@heroicons/react/24/outline';

function Navbar() {
  const location = useLocation();

  const navItems = [
    { name: 'Home', path: '/', icon: <HomeIcon className="h-6 w-6" /> },
    { name: 'About', path: '/about', icon: <InformationCircleIcon className="h-6 w-6" /> },
    // Add more nav items here
  ];

  return (
    <nav className="bg-white shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-between h-16">
          {/* Logo Section */}
          <div className="flex-shrink-0 flex items-center">
            <Link to="/" className="text-2xl font-bold text-blue-600">
              3D Showcase
            </Link>
          </div>
          
          {/* Navigation Links */}
          <div className="hidden md:flex md:items-center">
            {navItems.map((item) => (
              <Link
                key={item.name}
                to={item.path}
                className={`ml-4 flex items-center px-3 py-2 rounded-md text-sm font-medium ${
                  location.pathname === item.path
                    ? 'text-blue-600 bg-gray-200'
                    : 'text-gray-700 hover:text-blue-600'
                }`}
              >
                {item.icon}
                <span className="ml-1">{item.name}</span>
              </Link>
            ))}
          </div>

          {/* Mobile Menu Button */}
          <div className="flex items-center md:hidden">
            {/* Implement mobile menu toggle if needed */}
            {/* For simplicity, it's omitted here */}
          </div>
        </div>
      </div>

      {/* Mobile Menu */}
      {/* Implement mobile menu if needed */}
    </nav>
  );
}

export default Navbar;
