import React from 'react';
import { BrowserRouter as Router, Routes, Route, Link, useParams } from 'react-router-dom';
import ModelViewer from './ModelViewer';
import Navbar from './Navbar'; // Import Navbar

function Home() {
  return (
    <div className="flex flex-col items-center p-8 bg-gray-50 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-8 text-gray-800">3D Models Showcase</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10">
        {/* Doll 1 Card */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-xs hover:shadow-xl transition-shadow duration-300">
          <img src="/assets/doll2-thumbnail.jpg" alt="Doll 1" className="w-full h-48 object-contain" />
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Doll 1</h2>
            <p className="text-gray-600 mb-4">Click to view Doll 1</p>
            <Link to="/model/doll1" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors">View Model</Link>
          </div>
        </div>
        {/* Żółwik Card */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-xs hover:shadow-xl transition-shadow duration-300">
          <img src="/assets/zolwik.jpg" alt="Żółwik" className="w-full h-48 object-contain" />
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Żółwik</h2>
            <p className="text-gray-600 mb-4">Click to view Żółwik</p>
            <Link to="/model/zolwik" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors">View Model</Link>
          </div>
        </div>
        {/* Lisek Card */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-xs hover:shadow-xl transition-shadow duration-300">
          <img src="/assets/lisek.jpg" alt="Lisek" className="w-full h-48 object-contain" />
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Lisek</h2>
            <p className="text-gray-600 mb-4">Click to view Lisek</p>
            <Link to="/model/lisek" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors">View Model</Link>
          </div>
        </div>
        {/* Piesek Marvel Card */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-xs hover:shadow-xl transition-shadow duration-300">
          <img src="/assets/marvel.jpg" alt="Piesek Marvel" className="w-full h-48 object-contain" />
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Piesek Marvel</h2>
            <p className="text-gray-600 mb-4">Click to view Marvel</p>
            <Link to="/model/marvel" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors">View Model</Link>
          </div>
        </div>
        {/* Cameraman Card */}
        <div className="bg-white rounded-xl shadow-md overflow-hidden max-w-xs hover:shadow-xl transition-shadow duration-300">
          <img src="/assets/cameraman.jpg" alt="Cameraman" className="w-full h-48 object-contain" />
          <div className="p-6">
            <h2 className="text-xl font-bold text-gray-800 mb-2">Cameraman</h2>
            <p className="text-gray-600 mb-4">Click to view Cameraman</p>
            <Link to="/model/cameraman" className="inline-block bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-500 transition-colors">View Model</Link>
          </div>
        </div>
      </div>
    </div>
  );
}
function About() {
  return (
    <div className="flex flex-col items-center p-8 bg-gray-50 min-h-screen">
      <h1 className="text-4xl font-extrabold mb-4 text-gray-800">About Us</h1>
      <p className="text-gray-600 text-center max-w-2xl">
        Welcome to the 3D Models Showcase! Explore a variety of stunning 3D models rendered using the latest web technologies.
      </p>
    </div>
  );
}
function ModelViewerWrapper() {
  const { modelName } = useParams();
  return <ModelViewer modelName={modelName} />;
}

function App() {
  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Navbar /> {/* Add Navbar here */}
        <div className="flex-grow">
          <Routes>
            {/* Home Page Route with Cards */}
            <Route path="/" element={<Home />} />
            {/* About Page Route */}
            <Route path="/about" element={<About />} />
            {/* Dynamic Model Viewer Route */}
            <Route path="/model/:modelName" element={<ModelViewerWrapper />} />
          </Routes>
        </div>
        {/* Footer can be added here if needed */}
      </div>
    </Router>
  );
}

export default App;
